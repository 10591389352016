import styled, { css } from 'styled-components'
import { colors, device, themeClassName } from '../../../styles/GlobalVariables'
import VodAdLogo from '/public/assets/images/svg/ad-logo-vod.svg'
import AdLogo from '/public/assets/images/svg/ad-logo.svg'

export const Container = styled.div<{
  outOfPage: boolean
  isFlDarkTheme: boolean
}>`
  ${props => {
    if (props.theme.id === 'Maavaron_Desktop') {
      return css`
        position: fixed;
        top: ${props.outOfPage ? '-100vh' : '0'};
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background: ${props.theme.pageType === 'ott'
          ? 'linear-gradient(90deg, #06060D -34.07%, #0C0F1F 47.01%, #06060D 137.67%);'
          : props.isFlDarkTheme
          ? 'rgba(0, 7, 33, 1)'
          : colors.white};
        z-index: 9000;
      `
    }
    if (props.theme.id === 'Maavaron_ros') {
      return css`
        height: 0;
      `
    }
    return ''
  }}
  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    background: ${colors.tvGuideDark};
  }

  display: ${props => (props.theme.show ? 'flex' : 'none')};

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${device.medium} {
      width: unset;
      height: unset;
      flex-direction: column;
    }
  }
`

export const Content = styled.div`
  ${device.medium} {
    min-width: 970px;

    #Maavaron_Desktop {
      min-height: 250px;
    }
  }
`

export const Strip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  top: -34px;
  padding-bottom: 4px;
  background: transparent;

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    background: ${colors.tvGuideDark};
  }

  ${props =>
    props.theme.type === 'ott'
      ? css`
          justify-content: flex-end;
        `
      : ''}
`
export const SkipButton = styled.button<{ fontColor?: string }>`
  ${props => {
    if (props.theme.type === 'ott') {
      return css`
        cursor: pointer;
        color: ${colors.white};
        padding: 4px 20px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 27px;
        border: none;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      `
    }
    if (props.theme.isFaceLift) {
      return css`
        font-size: 22px;
        line-height: 1;
        font-weight: 600;
        cursor: pointer;
        background: none;
        border: none;
        color: ${props.fontColor};
        padding: 4px 16px;
        border: 1px solid rgba(77, 81, 100, 1);
        &:hover {
          opacity: 1;
        }
      `
    }
    return css`
      font-size: 22px;
      line-height: 1;
      font-weight: 600;
      cursor: pointer;
      background: none;
      border: none;

      ${themeClassName.vod} &, ${themeClassName.vodMain} & {
        color: ${colors.white};
      }
    `
  }}
`
export const PortalLogo = styled(AdLogo)`
  display: block;
  font-size: 102px;

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    display: none;
  }
`

export const VodLogo = styled(VodAdLogo)`
  display: none;
  font-size: 102px;

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    display: block;
  }
`
