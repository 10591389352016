import { AdId } from '../../../contexts'
import Ad from '../Ad'
import { Container } from './MidBox.styles'

export interface IProps {
  index: number
  collapse: boolean
}

export const MidBox = ({ index, collapse }: IProps) => {
  const id = `Mid_box_${index}` as AdId

  return (
    <Container data-name={id}>
      <Ad
        id={id}
        collapse={collapse}
        sizeMap={[
          {
            screen: [375, 0],
            ad: [336, 280],
          },
          {
            screen: [340, 0],
            ad: [300, 250],
          },
        ]}
      />
    </Container>
  )
}
