import styled from 'styled-components'
import { TaboolaOptions } from './types'

const TaboolaDetailsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: white;
  border: 2px lime solid;
  padding: 5px;
`
const TaboolaDetails = (options: TaboolaOptions) => {
  const elArr = Object.keys(options).map((key, index) => {
    return (
      <div key={index}>
        {key}: {options[key as keyof typeof options]}
      </div>
    )
  })
  return <TaboolaDetailsStyled>{elArr}</TaboolaDetailsStyled>
}
export default TaboolaDetails
