import {
  PocTaboolaList,
  PocTaboolaPageType,
  PocTaboolaSuffixId,
} from './TaboolaTypes'
import { TaboolaOptions, TaboolaType } from './types'

interface params {
  pageType: PocTaboolaPageType | typeof PocTaboolaList | string
  _isNativeApp: boolean
  index?: number
  suffixId: PocTaboolaSuffixId
  os: string | boolean
  isFaceLift?: boolean
}

export const getTaboolaProps = ({
  pageType,
  _isNativeApp,
  index,
  suffixId,
  os,
  isFaceLift,
}: params) => {
  let type: TaboolaType | null = { article: 'auto' },
    options: TaboolaOptions = {
      mode: '',
      container: '',
      placement: '',
      target_type: '',
    },
    id = ''
  const suffix = index ? `${suffixId}-${index}` : `${suffixId}`
  switch (pageType) {
    case PocTaboolaPageType.ott:
      type = {}
      switch (suffixId) {
        case PocTaboolaSuffixId.ottMidPageWidget:
          type = { category: 'auto' }
          id = `mid-page-all-shows-${suffix}`
          options = {
            mode: 'thumbnails-h',
            container: id,
            placement: `Mid Page All Shows ${suffix}`,
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.ottMidSeriesWidget:
          type = { video: 'auto' }
          id = `mid-page-show-stream-${index}`
          options = {
            mode: 'thumbnails-stream-1x1',
            container: id,
            placement: `Mid Page Show Stream ${index}`,
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.ottMidLiveWidget:
          type = { video: 'auto' }
          id = 'mid-page-live'
          options = {
            mode: 'thumbnails-g',
            container: id,
            placement: 'Mid Page Live',
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.tag:
    case PocTaboolaPageType.writer:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.widget:
          id =
            'taboola-below-category-thumbnails-widget' +
            (index ? `-${index}` : '')
          options = {
            mode: _isNativeApp
              ? 'thumbnails-tu-2x2'
              : 'thumbnails-stream-4x1-tu',
            container: id,
            placement:
              (_isNativeApp
                ? 'Mid Portal Widget'
                : 'Portal Category Mid Thumbnails') +
              (index ? ` ${index}` : ''),
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.hpvod:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-vod-thumbnails'
          options = {
            mode:
              _isNativeApp && os === 'iOS'
                ? 'thumbs-feed-01a'
                : 'thumbs-feed-01ab',
            container: id,
            placement: 'Below VOD Thumbnails',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.widget:
          id = 'taboola-vod-thumbnails' + (index ? `-${index}` : '')
          options = {
            mode: 'thumbnails-vod',
            container: id,
            placement:
              'VOD Category Mid Thumbnails' + (index ? `-${index}` : ''),
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.vod:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.widget:
          type = { video: 'auto' }
          id = 'taboola-vod-thumbnails' + (index ? `-${index}` : '')
          options = {
            mode: 'thumbnails-vod',
            container: id,
            placement:
              (_isNativeApp ? 'thumbnails-vod' : 'VOD Video Mid Thumbnails') +
              (index ? `-${index}` : ''),
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-vod-thumbnails'
          options = {
            mode:
              _isNativeApp && os === 'iOS'
                ? 'thumbs-feed-01a'
                : 'thumbs-feed-01ab',
            container: id,
            placement: 'Below VOD Thumbnails',
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.article_item_left_rail_thumbnails:
      type = { article: 'auto' }
      id = 'taboola-left-rail-widget'
      options = {
        mode: 'alternating-thumbnails-lr6',
        container: id,
        placement: 'Left Rail Widget',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.vod_item_left_rail_thumbnails:
      type = { video: 'auto' }
      id = 'taboola-left-rail-widget-2'
      options = {
        mode: 'alternating-thumbnails-lr4',
        container: id,
        placement: 'Left Rail Widget 2',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.playlist_in_vod:
      type = { video: 'auto' }
      id = 'taboola-below-player-vod'
      options = {
        mode: 'thumbnails-c',
        container: id,
        placement: 'Below Player Vod',
        target_type: 'mix',
        article: 'auto',
      }
      break
    case PocTaboolaPageType.home:
      type = { home: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-portal-thumbnails'
          options = {
            mode: !_isNativeApp
              ? 'alternating-thumbnails-s'
              : 'alternating-thumbnails-a',
            container: id,
            placement: 'Below Portal Thumbnails',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.widget:
          id =
            'taboola-below-homepage-thumbnails-widget' +
            (index ? `-${index}` : '')
          options = {
            mode: !_isNativeApp
              ? 'thumbnails-stream-4x1-tu'
              : 'thumbnails-tu-2x2',
            container: id,
            placement:
              (!_isNativeApp
                ? 'Portal HP Mid Thumbnails'
                : 'Mid Portal Widget') + (index ? `-${index}` : ''),
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.page:
    case PocTaboolaPageType.category:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.widget:
          id =
            'taboola-below-category-thumbnails-widget' + (index && '-' + index)
          options = {
            mode: _isNativeApp
              ? 'thumbnails-tu-2x2'
              : 'thumbnails-stream-4x1-tu',
            container: id,
            placement:
              (_isNativeApp
                ? 'Mid Portal Widget'
                : 'Portal Category Mid Thumbnails') + (index || ''),
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-portal-thumbnails'
          options = {
            mode: _isNativeApp
              ? 'alternating-thumbnails-a'
              : 'alternating-thumbnails-s',
            container: id,
            placement: 'Below Portal Thumbnails',
            target_type: 'mix',
            // bottom: suffix == "page-footer",
          }
          break
      }
      break
    case PocTaboolaPageType.news_hp:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.widget:
          type = { category: 'auto' }
          id = 'taboola-mivzakim-text-link' + (index ? `-${index}` : '')
          options = {
            mode: _isNativeApp
              ? 'thumbnails-stream-2x2-mob'
              : 'alternating-thumbnails-z',
            container: id,
            placement: 'HP_Grid Thumbnails' + (index ? `-${index}` : ''),
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-portal-thumbnails'
          options = {
            mode: _isNativeApp
              ? 'alternating-thumbnails-a'
              : 'alternating-thumbnails-s',
            container: id,
            placement: 'Below Portal Thumbnails',
            target_type: 'mix',
            // bottom: suffix == "page-footer",
          }
          break
      }
      break
    case PocTaboolaPageType.chanel26_1st:
      type = { category: 'auto' }
      id = 'taboola-big-brother-live-page-1'
      options = {
        mode: 'alternating-thumbnails-n2',
        container: 'taboola-big-brother-live-page-1',
        placement: 'big brother live page 1',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.chanel26_2st:
      type = { category: 'auto' }
      id = 'taboola-big-brother-live-page-2'
      options = {
        mode: 'alternating-thumbnails-n3',
        container: 'taboola-big-brother-live-page-2',
        placement: 'big brother live page 2',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.search:
      type = { search: 'auto' }
      id = _isNativeApp
        ? `taboola-Mid-Mobile-${suffix}`
        : `taboola-Mid-DK-${suffix}`
      options = {
        mode: _isNativeApp ? 'thumbnails-k' : 'thumbnails-j',
        container: id,
        placement: _isNativeApp ? `Mid_Mobile ${suffix}` : `Mid_DK ${suffix}`,
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.you_might_also_like:
      type = { video: 'auto' }
      id = 'taboola-mid-article-thumbnails'
      options = {
        mode: 'thumbnails-c-new',
        container: id,
        placement: 'Mid Article Thumbnails',
        target_type: 'mix',
      }
      break
    case PocTaboolaList.YOU_MIGHT_ALSO_LIKE_FOR_ITEM:
      type = { video: 'auto' }
      id = 'taboola-after-item-thumbnails'
      options = {
        mode: 'alternating-thumbnails-ba',
        container: id,
        placement: 'after item thumbnails',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.taboola_below_live_player:
      type = { video: 'auto' }
      id = 'taboola-below-live-player'
      options = {
        mode: 'thumbnails-4x1',
        container: id,
        placement: 'Below Live Player',
        target_type: 'mix',
      }
      break
    case PocTaboolaList.MOBILE_TIMELINE_BOX:
      type = { article: 'auto' }
      id = 'taboola-below-timeline-thumbnails'
      options = {
        mode: 'thumbnails-e',
        container: id,
        placement: 'Below Timeline Thumbnails',
        target_type: 'mix',
      }
      break
    case PocTaboolaList.DESKTOP_TIMELINE_BOX:
      type = { article: 'auto' }
      id = 'taboola-below-timeline-thumbnails-stream'
      options = {
        mode: 'thumbnails-stream',
        container: id,
        placement: 'Below Timeline Thumbnails Stream',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.notFound:
      type = { other: 'auto' }
      id = 'taboola-feed-below-404-thumbnails'
      options = {
        mode: 'alternating-thumbnails-b',
        container: id,
        placement: 'Feed Below 404 thumbnails',
        target_type: 'mix',
      }
      break
    case PocTaboolaPageType.newsFlash:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.specialWidget:
          id = 'taboola-mivzakim-text-link' + (index ? `-${index}` : '')
          options = {
            mode: _isNativeApp ? 'textlink-1x1-b' : 'textlink-1x1-desktop',
            container: id,
            placement:
              (_isNativeApp ? 'mivzakin text link' : 'Mivzakim Text Link') +
              (index ? ` ${index}` : ''),
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.mivzakimLeftRail:
          id = 'taboola-left-rail-widget'
          options = {
            mode: 'alternating-thumbnails-lr6',
            container: id,
            placement: 'Left Rail Widget',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-portal-thumbnails'
          options = {
            mode: _isNativeApp
              ? 'alternating-thumbnails-a'
              : 'alternating-thumbnails-s',
            container: id,
            placement: 'Below Portal Thumbnails',
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.article_vod:
    case PocTaboolaPageType.recipe:
    case PocTaboolaPageType.article:
      if (
        [PocTaboolaPageType.article, PocTaboolaPageType.recipe].includes(
          pageType
        )
      ) {
        type = { article: 'auto' }
      } else {
        type = { video: 'auto' }
      }

      switch (suffixId) {
        case PocTaboolaSuffixId.videoItem:
          id = 'taboola-leftrail_video-item'
          options = {
            mode: 'thumbnails-right-rail-1x2',
            container: id,
            placement: 'LeftRail_Video-item',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.articleLeftRail3:
          type = { video: 'auto' }
          id = 'taboola-left-rail-widget-3'
          options = {
            mode: 'alternating-thumbnails-lr3-new',
            container: id,
            placement: 'Left Rail Widget-3',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.articleLeftRail4:
          type = { video: 'auto' }
          id = 'taboola-left-rail-widget-4'
          options = {
            mode: 'alternating-thumbnails-lr4-new',
            container: id,
            placement: 'Left Rail Widget-4',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.articleLeftRail5:
          type = { article: 'auto' }
          id = 'taboola-left-rail-widget-5'
          options = {
            mode: 'alternating-thumbnails-lr5',
            container: id,
            placement: 'Left Rail Widget-5',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.articleLeftRail:
        case PocTaboolaSuffixId.recipeLeftRail:
          id = 'taboola-left-rail-widget'
          options = {
            mode: 'alternating-thumbnails-lr6',
            container: id,
            placement: 'Left Rail Widget',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-portal-thumbnails'
          options = {
            mode: !_isNativeApp
              ? 'alternating-thumbnails-s'
              : 'alternating-thumbnails-a',
            container: id,
            placement: 'Below Portal Thumbnails',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.midArticle:
          type = {}
          id = 'taboolaMidArticleItem'
          options = {
            mode: !_isNativeApp
              ? 'alternating-thumbnails-a-mid-article-video'
              : 'thumbnails-mid-article',
            container: id,
            placement: 'Mid Article Thumbnails',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.specialWidget:
          id = 'taboola-below-photo-thumbnails'
          options = {
            mode: !_isNativeApp
              ? 'alternating-thumbnails-photo-4x1'
              : 'alternating-thumbnails-a',
            container: id,
            placement: 'Top Article Thumbnails',
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.timeLine:
      switch (suffixId) {
        case PocTaboolaSuffixId.pageFooter:
          type = { article: 'auto' }
          id = 'taboola-below-portal-thumbnails'
          options = {
            mode: !_isNativeApp
              ? 'alternating-thumbnails-s'
              : 'alternating-thumbnails-a',
            container: id,
            placement: 'Below Portal Thumbnails',
            target_type: 'mix',
          }
          break
      }
      break
    case PocTaboolaPageType.epg:
      type = { category: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.epgLeftRail:
          id = 'taboola-tvguide---left-rail-widget'
          options = {
            mode: 'thumbnails-rr-1x6',
            container: id,
            placement: 'tvguide - Left Rail Widget',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-vod-thumbnails'
          options = {
            mode: os === 'iOS' ? 'thumbs-feed-01a' : 'thumbs-feed-01ab',
            container: id,
            placement: 'Below VOD Thumbnails',
            target_type: 'mix',
          }
          break
      }

      break
    case PocTaboolaPageType.live:
      type = { video: 'auto' }
      switch (suffixId) {
        case PocTaboolaSuffixId.pageFooter:
          id = 'taboola-below-vod-thumbnails'
          options = {
            mode: os === 'iOS' ? 'thumbs-feed-01a' : 'thumbs-feed-01ab',
            container: id,
            placement: 'Below VOD Thumbnails',
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.widget:
          id = 'taboola-live-top-thumbnails' + (index ? `-${index}` : '')
          options = {
            mode: _isNativeApp
              ? 'alternating-thumbnails-2x2'
              : 'alternating-thumbnails-4x1',
            container: id,
            placement: 'LIVE Top Thumbnails' + (index ? `-${index}` : ''),
            target_type: 'mix',
          }
          break
        case PocTaboolaSuffixId.midPageWidget:
          id = 'taboola-vod-thumbnails' + (index ? `-${index}` : '')
          options = {
            mode: 'thumbnails-vod',
            container: id,
            placement: _isNativeApp
              ? 'VOD thumbnails'
              : 'VOD Video Mid Thumbnails',
            target_type: 'mix',
          }
          break
      }
      break
    default:
      type = { article: 'auto' }
      id =
        (_isNativeApp
          ? 'taboola-mobile-below-article-thumbnails-2nd'
          : 'taboola-below-article-thumbnails-2nd') + (index ? `-${index}` : '')
      options = {
        mode: 'alternating-thumbnails-f2',
        container: id,
        placement: _isNativeApp
          ? 'Mobile Below Article Thumbnails 2nd'
          : 'Below Article Thumbnails 2nd',
        target_type: 'mix',
        article: 'auto',
      }
      break
  }
  if (isFaceLift) {
    options.mode += '-nd'
  }
  return { type, id, options }
}
