import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { AdId, useAdConfigs, usePage, useWindow } from '../../contexts'
import { isGridVodPage, isVodPlaylist } from '../../types'
import { utils } from '../Player/Helpers/utils'

export type SizeMapItem = {
  screen: [number, number]
  ad: [number, number]
}
export type SizeMap = SizeMapItem[]

export type IAd = {
  id?: AdId
  collapse?: boolean
  onRender?: (event: googletag.events.SlotRenderEndedEvent) => void
  sizeMap?: SizeMap
  mobileAdId?: AdId
  desktopAdId?: AdId
  sizeOverride?: googletag.GeneralSize | null
}

/**
 *  Component that allocates a Google Publisher Tag slot and tries to fill an ad.
 *  Width and height (or multiple sizes) have to be defined for the API to find a fitting ad.
 *  Targeting and other parameters are optional.
 *  More details at https://developers.google.com/publisher-tag/guides/get-started
 */
const Ad = ({
  id,
  mobileAdId,
  desktopAdId,
  collapse = false,
  sizeMap,
  onRender: renderCallback,
  sizeOverride,
}: IAd) => {
  const adConfig = useAdConfigs({ id, mobileAdId, desktopAdId, renderCallback })
  const [slot, setSlot] = useState<googletag.Slot | null>(null)
  const adsBlocked = usePage()?.PageMeta?.ads?.block.dfp
  const [forceNoResponse, setForceNoResponse] = useState<null | boolean>(null)

  useEffect(() => {
    if (
      id &&
      [
        'Maavaron_Desktop_T',
        'Maavaron_Desktop',
        'Maavaron_ros_T',
        'Maavaron_ros',
      ].includes(id)
    ) {
      if (utils.getParameterByName('response') === 'isempty') {
        sessionStorage.setItem('forceNoResponse', 'true')
      }
    }
    setForceNoResponse(!!sessionStorage.getItem('forceNoResponse'))
  }, [adConfig, id])

  useEffect(() => {
    if (adsBlocked) {
      return
    }

    const displayAd = () => {
      // Make a call only if the configurations have loaded and the slot is not already allocated
      if (!adConfig || slot) {
        return
      }

      // Flag [1, 1] ad size
      const isPixelSize = adConfig.size[0] === 1 && adConfig.size[1] === 1

      let adSize: googletag.GeneralSize
      if (sizeOverride) {
        adSize = sizeOverride as googletag.GeneralSize
      } else if (isPixelSize) {
        adSize = ['fluid'] as googletag.GeneralSize
      } else {
        adSize = adConfig.size as googletag.GeneralSize
      }

      window.googletag.cmd.push(() => {
        const newSlot = window.googletag.defineSlot(
          adConfig.name,
          adSize,
          adConfig.id
        )

        // If slot is not properly initialized, return
        if (!newSlot) {
          return
        }
        newSlot.addService(window.googletag.pubads())
        newSlot.setCollapseEmptyDiv(collapse)

        if (sizeMap) {
          const sizeMapping = googletag.sizeMapping()
          sizeMap.forEach(size => {
            sizeMapping.addSize(size.screen, size.ad)
          })
          const sizeMapArray = sizeMapping.addSize([0, 0], []).build()

          newSlot.defineSizeMapping(sizeMapArray)
        }

        window.googletag.display(adConfig.id)

        setSlot(newSlot)
      })
    }

    if (!adConfig || forceNoResponse === null) {
      return
    }

    displayAd()

    return () => {
      if (!slot) {
        return
      }

      window.googletag.cmd.push(() => {
        window.googletag.destroySlots([slot])
      })
    }
  }, [
    adConfig,
    slot,
    adsBlocked,
    collapse,
    sizeMap,
    sizeOverride,
    forceNoResponse,
  ])

  if (adsBlocked || !adConfig || forceNoResponse === null) {
    return null
  }

  return (
    <div id={adConfig.domId + (forceNoResponse ? '_force_no_response' : '')} />
  )
}

const AdWithKey = ({
  id,
  mobileAdId,
  desktopAdId,
  collapse = false,
  sizeMap,
  onRender: renderCallback,
  sizeOverride,
}: IAd) => {
  const { asPath } = useRouter()
  const page = usePage()
  const win = useWindow()

  if (!win) {
    return null
  }

  const isPlaylistPage = page
    ? isGridVodPage(page) && isVodPlaylist(page.Content.PageGrid[0])
    : false

  return (
    <Ad
      key={id + (isPlaylistPage ? '' : asPath)}
      id={id}
      mobileAdId={mobileAdId}
      desktopAdId={desktopAdId}
      collapse={collapse}
      sizeMap={sizeMap}
      onRender={renderCallback}
      sizeOverride={sizeOverride}
    />
  )
}

export default AdWithKey as typeof Ad
