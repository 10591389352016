import { useMemo, useState } from 'react'
import { usePage } from '../contexts'

export const useFL = () => {
  const page = usePage()
  const [fontColor, setFontColor] = useState('#000')
  const [isDarkTheme, setIsDarkTheme] = useState(false)
  const [isFaceLift, setIsFaceLift] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('#fff')

  useMemo(() => {
    if (page?.PageMeta) {
      if (page?.PageMeta?.themed) {
        setFontColor(page.PageMeta.themed.font_color)
        setIsDarkTheme(page.PageMeta.themed.font_color === '#fff')
        setBackgroundColor(page.PageMeta.themed.background_color)
      }
      setIsFaceLift(page.PageMeta.isFaceLift)
    }
  }, [page])

  return { fontColor, isDarkTheme, backgroundColor, isFaceLift }
}
