import { useCallback, useEffect, useState } from 'react'
import { isDesktop } from '../../../client'
import Ad from '../Ad'
import { Container, sizeMap } from './Ozen.styles'
import { AdId } from '../../../contexts'

type Props = {
  side: 'left' | 'right'
}

export const Ozen = ({ side }: Props) => {
  const [shouldRender, setShouldRender] = useState(false)
  const [adSize, setAdSize] = useState<number[]>([])

  useEffect(() => {
    // Only render on desktop
    setShouldRender(isDesktop())
  }, [])

  const onRender = useCallback(
    (event: googletag.events.SlotRenderEndedEvent) => {
      if (!Array.isArray(event.size)) {
        return
      }
      setAdSize(event.size)
      return () => null
    },
    []
  )

  if (!shouldRender) {
    return null
  }
  const sizeMapItem = adSize
    ? sizeMap.find(t => t.ad[0] === adSize[0] && t.ad[1] === adSize[1])
    : undefined

  const id = `Ozen_${side}` as AdId

  return (
    <Container side={side} size={sizeMapItem}>
      <Ad id={id} sizeMap={sizeMap} onRender={event => onRender(event)} />
    </Container>
  )
}
