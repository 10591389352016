import { useEffect, useMemo } from 'react'
import { isNativeApp } from '../../client'
import { playerConfig } from '../Player/Helpers/constants'
import TaboolaDiv from './TaboolaDiv'
import {
  PocTaboolaList,
  PocTaboolaPageType,
  PocTaboolaSuffixId,
} from './TaboolaTypes'
import { taboolaLog } from '../../utils'
import { getTaboolaProps } from './functions'
import { usePage } from '../../contexts'

type Props = {
  pageType: PocTaboolaPageType | typeof PocTaboolaList | string
  suffixId: PocTaboolaSuffixId
  index?: number
  os: 'Web' | 'Android' | 'iOS' | string | boolean
  pub: string
}

// TODO: remove default value of index
export const TaboolaForDiv = ({
  pageType,
  suffixId,
  index,
  os,
  pub,
}: Props) => {
  const _inWebView = playerConfig.inWebView
  const _isNativeApp = isNativeApp()
  const page = usePage()
  const { type, id, options } = useMemo(
    () =>
      getTaboolaProps({
        pageType,
        _isNativeApp,
        index,
        suffixId,
        os,
        isFaceLift: page?.PageMeta?.isFaceLift,
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_inWebView, index, os, pageType, suffixId]
  )

  useEffect(() => {
    taboolaLog({
      msg: 'show taboola  (push)',
      type: type,
      id: id,
      options: options,
      pub: pub,
    })
  }, [id, options, pub, type])

  return (
    <TaboolaDiv
      type={type}
      id={id}
      options={options}
      pub={pub}
      suffixId={suffixId}
    />
  )
}
