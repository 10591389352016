import NextLink from 'next/link'
import { Key } from 'react'
import { clientConfig } from '../../client/clientConfig'
import { config } from '../../lib'

type Props = {
  href?: string
  className?: string
  key?: Key
  children?: React.ReactNode
  target?: string
  ariaLabel?: string
  onClick?: () => void
  prefetch?: boolean
}

const isInternalUrl = (url?: string) => {
  if (url?.startsWith('/')) {
    return true
  }
  if (typeof window !== 'undefined') {
    return url?.startsWith(clientConfig.baseUrl)
  }
  return url?.startsWith(config.baseUrl)
}

const Link = ({
  href,
  className,
  key,
  ariaLabel,
  target,
  children,
  onClick,
}: Props) => {
  const getLinkRel = !href || isInternalUrl(href) ? undefined : 'nofollow'
  return href ? (
    <NextLink href={href} key={key}>
      <a
        rel={getLinkRel}
        className={className}
        key={key}
        target={target}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {children}
      </a>
    </NextLink>
  ) : (
    <a
      rel={getLinkRel}
      className={className}
      key={key}
      target={target}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default Link
