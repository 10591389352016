import React, { useMemo } from 'react'
import { TaboolaForDiv } from './TaboolaForDiv'
import {
  PocTaboolaList,
  PocTaboolaPageType,
  PocTaboolaSuffixId,
} from './TaboolaTypes'
import { utils } from '../Player/Helpers'
import { useTaboola } from './useTaboola'
import { usePage } from '../../contexts'

type Props = {
  pageType: PocTaboolaPageType | typeof PocTaboolaList | string
  index?: number
  suffixId: PocTaboolaSuffixId
}

export const Taboola = ({ pageType, index, suffixId }: Props) => {
  const { isTaboolaReady, os, taboolaPubName } = useTaboola()
  const { PageMeta } = usePage()
  const taboolaDisabled = useMemo(() => {
    return PageMeta && PageMeta.ads && PageMeta.ads.block.taboola
  }, [PageMeta])

  if (!isTaboolaReady || taboolaDisabled) {
    return null
  }

  return (
    <TaboolaForDiv
      key={`${pageType}-${suffixId}-${index}-${utils.getPageViewedInSession()}`}
      pageType={pageType}
      index={index}
      suffixId={suffixId}
      os={os}
      pub={taboolaPubName}
    />
  )
}
