export * from './TaboolaTypes'

export enum PocTaboolaPageType {
  vod = 'vod',
  article_item_left_rail_thumbnails = 'article-item-left-rail-thumbnails',
  vod_item_left_rail_thumbnails = 'vod-item-left-rail-thumbnails',
  playlist_in_vod = 'playlist_in_vod',
  home = 'home',
  page = 'page',
  epg = 'epg',
  category = 'category',
  chanel26_1st = 'chanel26-1st',
  chanel26_2st = 'chanel26-2st',
  search = 'search',
  you_might_also_like = 'you-might-also-like',
  taboola_below_live_player = 'taboola-below-live-player',
  article = 'article',
  article_vod = 'article-vod',
  recipe = 'recipe',
  timeLine = 'time-line',
  feed = 'feed',
  tag = 'tag',
  hpvod = 'hpvod',
  writer = 'writer',
  newsFlash = 'mivzakim',
  notFound = '404',
  live = 'live',
  news_hp = 'news-hp',
  ott = 'ott',
}

export const PocTaboolaList = {
  YOU_MIGHT_ALSO_LIKE_FOR_ITEM: 'you-might-also-like-for-item',
  DESKTOP_TIMELINE_BOX: 'desktop-timeline-box',
  MOBILE_TIMELINE_BOX: 'mobile-timeline-box',
} as const

export enum PocTaboolaSuffixId {
  articleFooter = 'article-footer',
  articleLeftRail = 'article-left-rail',
  articleLeftRail3 = 'taboola-left-rail-widget-3',
  articleLeftRail4 = 'taboola-left-rail-widget-4',
  articleLeftRail5 = 'taboola-left-rail-widget-5',
  videoItem = 'leftrail_video-item',
  recipeLeftRail = 'recipe-left-rail',
  midArticle = 'mid-article-item',
  youMightAlsoLike = 'you-might-also-like',
  pageFooter = 'page-footer',
  widget = 'widget',
  mivzakimLeftRail = 'mivzakim-left-rail',
  specialWidget = 'special-widget',
  epgLeftRail = 'epg-left-rail',
  null = 'null',
  midPageWidget = 'mid-page-widget',
  ottMidPageWidget = 'mid-page-all-shows',
  ottMidSeriesWidget = 'mid-series-widget',
  ottMidLiveWidget = 'mid-live-widget',
}
