import { useEffect, useState } from 'react'
import { isDesktop, isNativeApp } from '../../../client'
import { AdId, useOverlay } from '../../../contexts'
import Ad from '../Ad'
import { AdCloseIcon, Container, Content } from './Footerstrip.styles'
import { useRootStore } from '../../../store'
import { useRouter } from 'next/router'

const marginDesktop = 0
const marginMobile = 12

export const Footerstrip = ({
  onAdRender,
}: {
  onAdRender: (height: number) => void
}) => {
  const router = useRouter()
  const [firstPageView] = useRootStore(state => [state.firstPageView])
  const [hidden, setHidden] = useState(false)
  const [renderButton, setRenderButton] = useState(false)
  const [id, setId] = useState<AdId | null>(null)
  const [feedWasInView, setFeedWasInView] = useState(false)
  const { feedInView } = useOverlay()

  useEffect(() => {
    if (feedInView || feedWasInView) {
      setHidden(true)
      setFeedWasInView(true)
    }
  }, [feedInView, feedWasInView])

  useEffect(() => {
    let adId = 'Footerstrip'
    if (isNativeApp()) {
      adId = firstPageView ? 'Main_Footer_web' : 'Ros_Footer_web'
    }
    setId(adId as AdId)
    // isDesktop() ? setId("FooterStrip") : setId("Footerstrip")
  }, [firstPageView])

  const onRender = (event: googletag.events.SlotRenderEndedEvent) => {
    if (!event.isEmpty) {
      setRenderButton(true)
      onAdRender(
        (event?.size?.[1] as number) +
          (isDesktop() ? marginDesktop : marginMobile)
      )
    }
  }

  const onCloseButtonClick = () => {
    setHidden(true)
    onAdRender(0)
  }

  useEffect(() => {
    const onRouteChangeStart = () => {
      setHidden(false)
      /*
       * not sure if needed:
       * // setId(null)
       * */
      setFeedWasInView(false)
    }

    router.events.on('routeChangeStart', onRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [router.events])

  if (hidden || !id) {
    return null
  }

  return (
    <Container>
      {renderButton && id === 'FooterStrip' && (
        <AdCloseIcon onClick={onCloseButtonClick} />
      )}
      <Content>
        <Ad id={id} collapse={true} onRender={onRender} />
      </Content>
    </Container>
  )
}
